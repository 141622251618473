

/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkExactActiveClass: "selected-menu",
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [

        {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
            path: '',
            component: () => import('./layouts/main/Main.vue'),
            children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
              {
                path: '/',
                name: 'home1',
                component: () => import('./views/Home.vue')
              },
              {
                path: '/page2',
                name: 'page-2',
                component: () => import('./views/Page2.vue')
              },
              {
                path: '/home_user_dash',
                name: 'home_user_dash',
                pageTitle: 'Information',
                component: () => import('./views/HomeUserDash.vue')
              },
              {
                path: '/customer_dash',
                name: 'customer_dash',
                pageTitle: 'Information',
                component: () => import('./views/CustomerDash.vue')
              },
              {
                path: '/manage_dashboard',
                name: 'manage_dashboard',
                pageTitle: 'manage_dashboard',
                component: () => import('./views/ManageDashboard.vue')
              },
              {
                path: '/manage_news',
                name: 'manage_news',
                pageTitle: 'manage_news',
                component: () => import('./views/ManageNews.vue')
              },
              {
                path: '/manage_home',
                name: 'manage_home',
                pageTitle: 'manage_home',
                component: () => import('./views/ManageHome.vue')
              },
              {
                path: '/manage_transactions',
                name: 'manage_transactions',
                pageTitle: 'manage_transactions',
                component: () => import('./views/ManageTransactions.vue')
              },
              {
                path: '/manage_transactions_new',
                name: 'manage_transactions',
                pageTitle: 'manage_transactions',
                component: () => import('./views/ManageTransactions-custom.vue')
              },
              {
                path: '/manage_close_home',
                name: 'manage_close_home',
                pageTitle: 'manage_close_home',
                component: () => import('./views/ManageCloseHome.vue')
              },
              {
                path: '/manage_vip',
                name: 'manage_vip',
                pageTitle: 'manage_vip',
                component: () => import('./views/ManageVip.vue')
              },
              {
                path: '/manage_request_help',
                name: 'manage_request_help',
                pageTitle: 'manage_request_help',
                component: () => import('./views/ManageRequestHelp.vue')
              },
              {
                path: '/home-user-news',
                name: 'home-user-news',
                pageTitle: 'News',
                component: () => import('./views/HomeUserNews.vue')
              },
              {
                path: '/close-house',
                name: 'close-house',
                pageTitle: 'Close House',
                component: () => import('./views/OpenCloseHouse.vue')
              },
              {
                path: '/house-request-help',
                name: 'house-request-help',
                pageTitle: 'HouseRequestHelp',
                component: () => import('./views/HouseRequestHelp.vue')
              },
              {
                path: '/home-user-vip',
                name: 'home-user-vip',
                pageTitle: 'VIP',
                component: () => import('./views/HomeUserVip.vue')
              },
              {
                path: '/stamp',
                name: 'stamp',
                pageTitle: 'Stamp House',
                component: () => import('./views/StampHouse.vue')
              },
            ],
        },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
        // =============================================================================
        // PAGES
        // =============================================================================
              {
                path: '/pages/login',
                name: 'page-login',
                component: () => import('@/views/pages/Login.vue')
              },
              {
                path: '/pages/error-404',
                name: 'page-error-404',
                component: () => import('@/views/pages/Error404.vue')
              },
              {
                path: '/pages/error-403',
                name: 'page-error-403',
                component: () => import('@/views/pages/Error403.vue')
              },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

export default router
